export default function ({ redirect }) {
  function isChromeAboveVersion110() {
    const userAgent = navigator.userAgent;
    const chromeMatch = userAgent.match(/Chrome\/(\d+)/);

    if (chromeMatch && chromeMatch.length > 1) {
      const version = parseInt(chromeMatch[1], 10);
      return version > 110;
    }

    return false;
  }

  if (!isChromeAboveVersion110()) {
    const warning = document.createElement("div");
    warning.style.position = "fixed";
    warning.style.top = "0";
    warning.style.left = "0";
    warning.style.width = "100%";
    warning.style.height = "100%";
    warning.style.backgroundColor = "white";
    warning.style.zIndex = "1000";
    warning.style.display = "flex";
    warning.style.justifyContent = "center";
    warning.style.alignItems = "center";
    warning.style.padding = "20px";
    warning.style.boxSizing = "border-box";
    warning.style.fontSize = "16px"; // Default font size
    warning.style.textAlign = "center"; // Ensure text is centered
    warning.style.overflow = "auto"; // Adds scroll if content overflows
    warning.style.wordWrap = "break-word"; // Ensure words break properly
    

    warning.innerHTML = `<div style="display:flex;flex-direction:column;align-items:center; gap: 10px;"><span>This site requires Chrome or a Chrome-based browser to run.</span><br /><span>Please use <a style="padding: 0 5px;" href="https://www.google.com/chrome/">Chrome</a> to access this site.</span></div>`;
    document.body.appendChild(warning);

    // Responsive font size and layout
    const styleElement = document.createElement("style");
    styleElement.textContent = `
      @media (min-width: 768px) {
        div { font-size: 18px; }
      }
      @media (min-width: 1024px) {
        div { font-size: 20px; padding: 40px; }
      }
    `;
    document.head.appendChild(styleElement);

    document.body.style.display = 'block'; // Show only the warning
  } else {
    document.body.style.display = 'block'; // All good, show the app
  }
}
